import React from 'react';
import PropTypes from 'prop-types';

import ImageWithLogo from '../../core/ImageWithLogo';

import { BrandBannerWrapper } from './styles';

const BrandBanner = ({ brand }) => {
  const { featureImage, imageLogo, leadImage } = brand.brandReference[0];
  return (
    <BrandBannerWrapper>
      <ImageWithLogo
        className={`${leadImage ? 'onlyDesktop ' : ''}brandBannerWrapper`}
        srcImage={featureImage}
        srcLogo={imageLogo}
      />
      {leadImage && (
        <ImageWithLogo
          className="onlyMobile brandBannerWrapper"
          srcImage={leadImage}
          srcLogo={imageLogo}
        />
      )}
    </BrandBannerWrapper>
  );
};
BrandBanner.propTypes = {
  brand: PropTypes.shape({}).isRequired,
};
BrandBanner.defaultProps = {};
export default BrandBanner;
