import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const BrandBannerWrapper = styled.div`
  width: 100%;
  height: 560px;
  margin-bottom: 2.8125rem;
  &:hover {
    .imageWithLogoLogo {
      max-width: 60%;
      max-height: 60%;
      top: 45%;
    }
    .imageWithLogoCircle {
      width: 215px;
      height: 215px;
    }
    .imageWithLogoImage {
      .gatsby-image-wrapper {
        transform: scale(1);
      }
    }
  }
  .imageWithLogo {
    overflow: hidden;
    .imageWithLogoCircle {
      width: 375px;
      height: 375px;
      left: -36px;
      bottom: -91px;
      .imageWithLogoLogo {
      }
    }
    &,
    .imageWithLogoImage {
      width: 100%;
      height: 560px;
      object-position: left center;
    }
  }
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    height: 166vw;
    .imageWithLogo {
      &,
      .imageWithLogoImage {
        height: 100%;
        /* height: 325px; */
      }
      .imageWithLogoImage {
        object-position: center bottom;
      }
      .imageWithLogoCircle {
        width: 215px;
        height: 215px;
        left: -22px;
        bottom: -46px;
      }
    }
  }
`;
