import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import Image from '../core/Image';
import GridList from '../core/GridList';

export const SectionProducts = styled.section`
  .productListWrapper {
    grid-template-columns: repeat(auto-fill, minmax(323px, 1fr));
    grid-column-gap: 1.9875rem;
    @media only screen and (max-width: ${themeGet('breakpoints.max')}) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media only screen and (max-width: ${themeGet('breakpoints.lg')}) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
      grid-template-columns: repeat(1, 1fr);
      grid-row-gap: 2.5rem;
    }
  }
`;
export const ProductListWrapper = styled(GridList)``;
export const ProductItem = styled.div`
  display: inline-block;
  width: 100%;
  min-width: 100%;
  .productCTA {
    display: inline-block;
    margin-top: 2.25rem;
  }
  a {
    margin-bottom: 0;
  }
  & > a,
  & > a > span {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;
export const ProductThumbnail = styled(Image)`
  width: 100%;
  height: auto;
  object-fit: contain;
  object-position: center center;
  display: inline-block;
  border: 1px solid ${themeGet('colors.formInputBorder')};
  border-radius: 5px;
  margin-bottom: 1.5rem;
  @media only screen and (max-width: ${themeGet('breakpoints.lg')}) {
    width: 100%;
    margin-bottom: 0.25rem;
  }
`;
export const ProductBlockNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    margin-left: 35px;
    margin-bottom: 37px;
  }
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    align-items: baseline;
    margin-bottom: 2rem;
    h2 {
      margin-top: 0;
      margin-left: 1rem;
      margin-bottom: 0;
    }
  }
`;
export const ProductBlockNavLinks = styled.div`
  .productNavLink {
    margin-right: 1rem;
    color: ${themeGet('colors.spinDark')};
  }
`;
export const ProductItemMetaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  h3 {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.2px;
    font-family: ${themeGet('fonts.sans')};
    color: rgb(0, 22, 85);
    font-weight: 600;
    margin-bottom: 2rem;
  }
  .inline-rating-summary-wrapper {
    padding: 20px 0px 20px 5px
  }

  [data-bv-show=inline_rating] .bv_main_container .bv_stars_svg_no_wrap {
    display: flex!important
    align-items: center !important
    white-space: nowrap !important
  }
`;
