import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { LocaleProvider } from '../../core/Context/LocaleProvider';
import { getTranslation } from '../../../utils/helpers';
import LinkList from '../../core/LinkList';
import LinkGroupWithImages from '../../core/LinkGroupWithImages';
import arrowRight from '../../../images/arrowRight.svg';

import { BrandHeaderBarWrapper, AllBrandsLink } from './styles';

const BrandHeaderBar = ({ pageName, brandReference, headingLevel , siteConfiguration}) => {
  if (!brandReference) return null;

  const locale = useContext(LocaleProvider).code;
  const allBrandsTranslation = getTranslation('all_brands',siteConfiguration.translations,locale,);

  const iconBlock = {
    // description: brandReference.followText,
    links:
      brandReference.socialMedia &&
      brandReference.socialMedia
        .filter((item) => {
          return item.imageSrc !== null;
        })
        .map((item) => {
          if (!item.imageSrc) {
            return null;
          }
          return {
            linkURL: item.linkUrl,
            imageSrc: item.imageSrc.file.url,
            altText: item.imageSrc.text,
          };
        }),
  };
  return (
    <BrandHeaderBarWrapper className="brandHeaderBarWrapper pageNavigation">
      <AllBrandsLink to={`/${locale}/brands` || ''}>
        <span className="arrow">
          <img src={arrowRight} className="arrowLeft" />
        </span>
        {allBrandsTranslation}
      </AllBrandsLink>
      <h1>{pageName}</h1>
      {iconBlock && ( // +1 because title is above
        <LinkGroupWithImages {...iconBlock} headingLevel={headingLevel + 1} />
      )}
      <div className="brandHeaderBarWrapperLinks">
        {brandReference &&
          brandReference.brandSiteUrl &&
          !brandReference.brandSiteUrl.length && (
            <LinkList
              links={brandReference.brandSiteUrl}
              ctaStyle="solid-on-dark-bg hvr-bounce-to-right"
            />
          )}
      </div>
    </BrandHeaderBarWrapper>
  );
};
BrandHeaderBar.propTypes = {
  pageName: PropTypes.string,
  brandReference: PropTypes.shape({}).isRequired,
  headingLevel: PropTypes.number.isRequired,
};

BrandHeaderBar.defaultProps = {
  pageName: null,
};

export default BrandHeaderBar;
