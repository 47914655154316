import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { Link } from 'gatsby';

export const AllBrandsLink = styled(Link)`
  position: absolute;
  top: 1.7rem;
  /* left: 2.25rem; */
  color: ${themeGet('colors.spinDark')};
  .arrow {
    margin-right: 0.6875rem;
    .arrowLeft {
      transform: scaleX(-1);
      height: 11px;
      position: relative;
      top: -4px;
    }
  }

  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    top: 1rem;
  }
`;

export const BrandHeaderBarWrapper = styled.nav`
  &.pageNavigation.brandHeaderBarWrapper {
    h1 {
      font-size: 2.5rem;
      line-height: 2.8125rem;
      padding-right: 2rem;
    }
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 165px;
    > h1 {
      flex-grow: 1;
    }
    .styledLinkGroup {
      width: unset;
      flex-grow: 1;
      h2 {
        font-size: 1.125rem;
        margin-bottom: 0.875rem;
        line-height: 1em;
        font-family: 'Open Sans', sans-serif;
      }
      ul {
        margin-right: 0;
        li {
          width: 60px;
          height: 60px;
          margin-right: 1rem;

          img {
            display: inline-block;
            width: 100%;
            height: 100%;
            position: static;
            top: 0;
          }
        }
        li:last-child {
          margin-right: 0;
        }
      }
    }
    .brandHeaderBarWrapperLinks {
      margin-right: 1.9375rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-grow: 4;
      text-align: right;
      display: block;
      .styledLinkList {
        margin-right: 2.3125rem;
        margin-top: 1.5rem;
        a {
          height: 60px;
          min-height: 60px;
          font-size: 1.2rem;
        }
      }
    }
    @media only screen and (max-width: ${themeGet('breakpoints.lg')}) {
      > h1 {
        flex-grow: unset;
        padding-right: 1rem;
      }
      .styledLinkGroup {
        flex-grow: unset;
        ul {
          flex-wrap: nowrap;
          li {
            width: 40px;
            height: 40px;
          }
        }
      }
      .brandHeaderBarWrapperLinks {
        flex-grow: unset;
      }
    }

    @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
      flex-direction: column;
      align-items: start;
      h1 {
        margin-top: 2.5rem;
        margin-bottom: 1rem;
      }
      .styledLinkGroup {
        h2 {
          margin-bottom: 0.8125rem;
        }
        margin-bottom: 1rem;
      }
      .brandHeaderBarWrapperLinks {
        flex-direction: column;
        width: 100%;
        align-items: start;
        display: flex;
        .styledLinkList {
          margin: 0;
          margin-bottom: 1.25rem;
          a {
            min-width: 200px;
            margin-bottom: 0;
            font-size: 1.1rem;
          }
        }
      }
    }
  }
`;
